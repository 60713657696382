import React from 'react';
import Seo from '../components/molecules/Seo';
import styled from 'styled-components';
import TwoColumn from '../components/organisms/TwoColumn';
import Image from '../assets/uf_contact.inline.svg';

const Holder = styled.div`
  p { ${props => props.theme.fluidType( 1 )}; }
  h2.h3 { 
    margin-top: 3rem; 
    &:first-child {
      margin-top: 0;
    }
  }
`;

const ImageHolder = styled.div`
  padding: 2rem;
  @media( ${props => props.theme.breakpoints.md} ) { 
    padding: 0;
  }
`;

function ContactPage() {

  return (
    <>
      <Seo title="Contact" description="We want to hear from you! Find our contact details here."/>
      <Holder>
        <TwoColumn>
          <div>
            <h1>We want to hear from you!</h1>
            <h2 className="h3">Email questions, ideas, poems and jokes to:</h2>
            <p>
              <a href="mailto:hello@unicornsforever.club" target="_blank" rel="noopener noreferrer">hello@unicornsforever.club</a>
            </p>
            <h2 className="h3">Send clothes for the recycling program, returns, drawings and odd
              socks to:</h2>
            <p>4/1 Aitken Ave, <br/>Queenscliff, <br/>NSW 2096.</p>
            <h2 className="h3">DM, comment or waste your valuable downtime aimlessly scrolling on
              our instagram at:</h2>
            <p>
              <a href="https://www.instagram.com/unicornsforeverclub/" target="_blank" rel="noopener noreferrer">@uf_unicorns_foreva</a>
            </p>
            <h2 className="h3">Want to stock our stuff?</h2>
            <p>We run a thorough and very tough selection process on people wanting to stock our
              products... <b>But we are happy to inform you that you have been approved! You are
                in!</b></p>
            <p>Email us
              on <a href="mailto:hello@unicornsforever.club" target="_blank" rel="noopener noreferrer">hello@unicornsforever.club</a> for
              details.</p>
          </div>
          <div>
            <ImageHolder>
              <Image/>
            </ImageHolder>
          </div>
        </TwoColumn>


      </Holder>
    </>
  )
}

export default ContactPage;

